import React, { useImperativeHandle } from 'react';
import { filterText, filterNumbers } from '../functions/functions'

type DicationaryTypes = "all_en" | "otcwl" | "sowpods" | "wwf2" | "wordle"

export const Options = React.forwardRef((props, ref) => {

  const [showOptions, setShowOptions] = React.useState(false)
  const [dictionary, setDictionary] = React.useState< DicationaryTypes >("all_en")
  const [starts_with, setStarts_with] = React.useState("")
  const [ends_with, setEnds_with] = React.useState("")
  const [shorter_than, setShorter_than] = React.useState<number | ''>('')
  const [longer_than, setLonger_than] = React.useState<number | ''>('')
  const [length, setLength] = React.useState<number | ''>('')
  const [must_contain, setMust_contain] = React.useState("")
  const [must_not_contain, setMust_not_contain] = React.useState("")
  const [must_contain_multiple, setMust_contain_multiple] = React.useState("")
  const [must_contain_char1, setMust_contain_char1] = React.useState("")
  const [must_contain_char2, setMust_contain_char2] = React.useState("")
  const [contains, setContains] = React.useState("")
  const [not_contains, setNot_contains] = React.useState("")
  const [include_letters, setInclude_letters] = React.useState("")
  const [exclude_letters, setExclude_letters] = React.useState("")
  const [contains_multiple, setContains_multiple] = React.useState("")
  const [contains_char1, setContains_char1] = React.useState("")
  const [contains_char2, setContains_char2] = React.useState("")
  const [pattern, setPattern] = React.useState("")
  const [regexp, setRegexp] = React.useState("")

  const optionsData = [
    {
      name: "starts_with",
      text: "Word starts with:",
      value: starts_with,
      onChange: (e: string) => setStarts_with(filterText(e))
    },
    {
      name: "ends_with",
      text: "Word ends with:",
      value: ends_with,
      onChange: (e: string) => setEnds_with(filterText(e))
    },
    {
      name: "shorter_than",
      text: "Word length shorter than:",
      value: shorter_than,
      onChange: (e: string) => setShorter_than(filterNumbers(e))
    },
    {
      name: "longer_than",
      text: "Word length longer than:",
      value: longer_than,
      onChange: (e: string) => setLonger_than(filterNumbers(e))
    },
    {
      name: "length",
      text: "Word length is:",
      value: length,
      onChange: (e: string) => setLength(filterNumbers(e))
    },
    {
      name: "must_contain",
      text: "Word must contain:",
      value: must_contain,
      onChange: (e: string) => setMust_contain(filterText(e))
    },
    {
      name: "must_not_contain",
      text: "Word must not contain:",
      value: must_not_contain,
      onChange: (e: string) => setMust_not_contain(filterText(e))
    },
    {
      name: "must_contain_multiple",
      text: "Word must contains multiple letters from:",
      value: must_contain_multiple,
      onChange: (e: string) => setMust_contain_multiple(filterText(e))
    },
    {
      name: "must_contain_char1",
      text: "must_contain_char1: unsure",
      value: must_contain_char1,
      onChange: (e: string) => setMust_contain_char1(filterText(e))
    },
    {
      name: "must_contain_char2",
      text: "must_contain_char2: unsure",
      value: must_contain_char2,
      onChange: (e: string) => setMust_contain_char2(filterText(e))
    },
    {
      name: "contains",
      text: "Word contains (wildcard as _):",
      value: contains,
      onChange: (e: string) => setContains(filterText(e, "_"))
    },
    {
      name: "not_contains",
      text: "Word must include following letters:",
      value: not_contains,
      onChange: (e: string) => setNot_contains(filterText(e))
    },
    {
      name: "include_letters",
      text: "Word must include following letters:",
      value: include_letters,
      onChange: (e: string) => setInclude_letters(filterText(e))
    },
    {
      name: "exclude_letters",
      text: "Word must not include following letters:",
      value: exclude_letters,
      onChange: (e: string) => setExclude_letters(filterText(e))
    },
    {
      name: "contains_multiple",
      text: "Word contains multiple letters from:",
      value: contains_multiple,
      onChange: (e: string) => setContains_multiple(filterText(e))
    },
    {
      name: "contains_char1",
      text: "contains_char1: unsure",
      value: contains_char1,
      onChange: (e: string) => setContains_char1(filterText(e))
    },
    {
      name: "contains_char2",
      text: "contains_char2: unsure",
      value: contains_char2,
      onChange: (e: string) => setContains_char2(filterText(e))
    },
    {
      name: "pattern",
      text: "regex pattern:",
      value: pattern,
      onChange: (e: string) => setPattern(e)
    },
    {
      name: "regexp",
      text: "regexp: I assume regexp stands for regular expression but I can't seem to find the format for it to work. Also, regex works in the previous input labeled 'pattern'?",
      value: regexp,
      onChange: (e: string) => setRegexp(e)
    },
  ]

  const queries = new URLSearchParams(window.location.search.slice(1))
  let optionsReq = [
    "starts_with",
    "ends_with",
    "contains",
    "length",
    "dictionary"
  ]
  const querySort = queries.get("sort")
  const sort: 'az' | 'za' | 'points' = querySort === 'az' || querySort === 'za' || querySort === 'points' ? querySort : 'az'
  if (queries.get("options") !== null) {
    try {
      optionsReq = JSON.parse(queries.get("options")!)
    } catch (e) {
      // do nothing
    }
    if (queries.get("options")!.includes("all")) {
      optionsReq = optionsData.map(e => e.name).concat(["dictionary"])
    }
  }

  useImperativeHandle(ref, () => ({
    urlFromOptions: (letters: string, options?: urlFuncOptions) => {
      let lengthParam = 0;
      let page_token = 1;
      if (options) {
        lengthParam = options.length ?? 0;
        page_token = options.page_token ?? 1
      }
      lengthParam = length ? length : lengthParam
      let requestObj: any = optionsData.map(e => ({key: e.name, value: e.value})).reduce((a, b: any) => ({...a, [b.key]: b.value}), {})
      Object.keys(requestObj).forEach((key: string) => {
        // handle length, pattern, regexp separately. Remove empty or unused values
        if (!optionsReq.includes(key) || !requestObj[key] || key === "length" || key === "pattern" || key === "regexp") {
          delete requestObj[key]
        }
      })
      let appendQuery: string = JSON.stringify(requestObj).split("").filter(char => !["{", "\"", "}"].includes(char)).join("").replaceAll(":", "=").replaceAll(",", "&")

      return `https://fly.wordfinderapi.com/api/search?${letters ? `letters=${letters}&` : ''}&length=${lengthParam}&page_token=${page_token}&page_size=20&word_sorting=${sort}&group_by_length=true&dictionary=${dictionary}${appendQuery ? `&${appendQuery}` : ""}${pattern ? `&pattern=${pattern}` : ''}${regexp ? `&regexp=${regexp}` : ''}`
    }
  }))

  return (
    <div className="options">
      <div
        className="optionsTitle"
        onClick={() => setShowOptions((current) => !current)}
      >
        <div>Options</div>
        <div className={showOptions ? "arrowUp" : "arrowDown"}>⌃</div>
      </div>
      <form className={`${showOptions ? 'formOpen' : 'formClosed'}`}>
        <>
          {optionsData.map((option) => (
            optionsReq.includes(option.name) && (
              <div key={option.name}>
                {option.text}
                <input
                  value={option.value}
                  onChange={(e) => option.onChange(e.target.value)}
                />
              </div>
            )
          ))}
        </>
        {optionsReq.includes("dictionary") && (
          <div>
            Dictionary:
            <select
              value={dictionary}
              onChange={(e) => setDictionary(e.target.value as DicationaryTypes)}
            >
              <option value="all_en">All Dictionaries</option>
              <option value="otcwl">Scrabble US</option>
              <option value="sowpods">Scrabble UK</option>
              <option value="wwf2">Words with Friends</option>
              {/* <option value="wordle">Wordle</option> */}
            </select>
          </div>
        )}
      </form>
    </div>
  )
})

export default Options;

