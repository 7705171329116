import React from 'react';
import Word from './Word';
import { contrastABvsHex } from '../functions/functions'

type WordListProps = {
  wordPage: WordPage
  query: string
  urlFunc: Function
}

function WordList({wordPage, query, urlFunc}: WordListProps) {
  const [moreButton, setMoreButton] = React.useState(false)
  const [wordPageNumber, setWordPageNumber] = React.useState(1)
  const [wordList, setWordList] = React.useState<WordListElement[]>([]);

  function loadMoreWords() {
    fetch(urlFunc(query, {length: wordPage.length, page_token: wordPageNumber + 1}))
    .then(res => res.json())
    .then((json: any) => {
      setWordList((currentList) => currentList.concat(json.word_pages[0].word_list))
      setMoreButton(json.word_pages[0].num_pages > json.word_pages[0].current_page)
      setWordPageNumber((i) => i+1)
    })
  }

  React.useEffect(() => {
    setWordList(wordPage.word_list)
    setMoreButton(wordPage.current_page < wordPage.num_pages)
  }, [query, wordPage.word_list, wordPage.current_page, wordPage.num_pages])

  const queries = new URLSearchParams(window.location.search.slice(1))
  const buttonStyle = {
    color: `${contrastABvsHex(`#${queries.get("highlight") ?? 'FFFFFF'}`, `#${queries.get("background") ?? 'FFFFFF'}`, `#${queries.get("color") ?? '000000'}`)}`
  }

  return (
    <div className="wordList">
      {wordPage.length} letter words:
        <ul className="list">
          {wordList.map((word: WordListElement, index: number) => (
            <Word
              key={index}
              word={word}
            />
          ))}
        </ul>
      {moreButton && (
        <button
          className="button"
          onClick={loadMoreWords}
          style={buttonStyle}
        >
          Load More
        </button>
      )}
    </div>
  );
}

export default WordList;
