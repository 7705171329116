import React from 'react';
import { filterText } from '../functions/functions'
import WordList from './WordList';

function WordleSolver() {
  const [greyLetters, setGreyLetters] = React.useState("");
  const [pos1, setPos1] = React.useState("")
  const [pos2, setPos2] = React.useState("")
  const [pos3, setPos3] = React.useState("")
  const [pos4, setPos4] = React.useState("")
  const [pos5, setPos5] = React.useState("")
  const [yellowPos1, setYellowPos1] = React.useState("")
  const [yellowPos2, setYellowPos2] = React.useState("")
  const [yellowPos3, setYellowPos3] = React.useState("")
  const [yellowPos4, setYellowPos4] = React.useState("")
  const [yellowPos5, setYellowPos5] = React.useState("")
  const [hardmode, setHardMode] = React.useState(false)
  const [results, setResults] = React.useState<WordFinderResult | null>(null)
  const [guess, setGuess] = React.useState(": RAISE")
  const [showSpin, setShowSpin] = React.useState(false)
  const posInputRef = React.useRef(null)
  const posArray = [
    {
      value: pos1,
      setter: setPos1
    },
    {
      value: pos2,
      setter: setPos2
    },
    {
      value: pos3,
      setter: setPos3
    },
    {
      value: pos4,
      setter: setPos4
    },
    {
      value: pos5,
      setter: setPos5
    }
  ]
  const yellowPosArray=[
    {
      value: yellowPos1,
      setter: setYellowPos1
    },
    {
      value: yellowPos2,
      setter: setYellowPos2
    },
    {
      value: yellowPos3,
      setter: setYellowPos3
    },
    {
      value: yellowPos4,
      setter: setYellowPos4
    },
    {
      value: yellowPos5,
      setter: setYellowPos5
    }
  ]

  function wordleURLFunc(path: string) {
    const green = `${pos1},${pos2},${pos3},${pos4},${pos5}`
    const yellow = `${yellowPos1},${yellowPos2},${yellowPos3},${yellowPos4},${yellowPos5}`
    return `/api/${path}/?green=${green}&yellow=${yellow}&grey=${greyLetters}${hardmode ? '&hardmode=true' : ''}`;
  }

  React.useEffect(() => {
    fetch(wordleURLFunc('wordle-helper'))
    .then(res => {
      return res.json()
    })
    .then((json: any) => {
      let parsedJSON: any = {
        word_pages: [{
          current_page: 1,
          length: 5,
          num_pages: 1,
          num_words: json.possible.length,
          word_list: json.possible.map((e: string) => ({
            points: null,
            word: e,
            wildcards: []
          }))
        }]
      };
      setResults(parsedJSON)
    })
    .catch(() => {})
  // React doesn't like wordleURLFunc not being a dependency? but wordleURLFunc is just a function?
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greyLetters, pos1, pos2, pos3, pos4, pos5, yellowPos1, yellowPos2, yellowPos3, yellowPos4, yellowPos5])

  React.useEffect(() => {
    const posInput = posInputRef.current
    const keyListener = function(event: KeyboardEvent) {
      if ("thequickbrownfoxjumpsoverthelazydog ".split("").includes(event.key)) {
        if (event.key === " ") {
          posArray[parseInt((event.target as HTMLInputElement).name.slice(5)) - 1].setter("");
        } else {
          posArray[parseInt((event.target as HTMLInputElement).name.slice(5)) - 1].setter(event.key);
        }
        if ((event.target as HTMLInputElement).nextSibling !== null) {
          ((event.target as HTMLInputElement).nextSibling as HTMLElement).focus()
        }
      }
    }
    const deleteListener = function(event: KeyboardEvent) {
      if (event.key === "Backspace") {
        if ((event.target as HTMLInputElement).value !== "") {
          posArray[parseInt((event.target as HTMLInputElement).name.slice(5)) - 1].setter("");
          (event.target as HTMLInputElement)!.focus()
        } else if ((event.target as HTMLInputElement).previousSibling !== null) {
          ((event.target as HTMLInputElement).previousSibling as HTMLElement).focus();
          posArray[parseInt(((event.target as HTMLInputElement).previousSibling as HTMLInputElement).name.slice(5)) - 1].setter("")
        }
      }
    }
    if (posInput) {
      (posInput as HTMLDivElement).addEventListener('keypress', keyListener);
      (posInput as HTMLDivElement).addEventListener('keydown', deleteListener);
    }
    return () => {
      posInput && (posInput as HTMLDivElement).removeEventListener('keypress', keyListener)
      posInput && (posInput as HTMLDivElement).removeEventListener('keydown', deleteListener)
    }
  })

  const getWordleGuess = function() {
    setShowSpin(true)
    fetch(wordleURLFunc('wordle-solver'))
    .then(res => {
      if (res.ok !== true) {
        setGuess(` some more: not enough information to generate guess.`)
      }
      setShowSpin(false)
      return res.json()
    })
    .then((json: any) => {
      setGuess(`: ${json.guess.toUpperCase()}`)
    })
    .catch(() => {})
  }

  return (
    <div className="wordle">
      <form>
        Grey Letters:
        <input
          value={greyLetters}
          onChange={(e) => setGreyLetters(filterText(e.target.value))}
        />
        Yellow Letters:
        <div className="yellowPositionalInput">
          { yellowPosArray.map((e, i: number) => (
            <div key={`yellow${i + 1}`}>
              <span>Position {i + 1}:</span>
              <input
                key={`yellow${i}`}
                name={`${i + 1}`}
                value={e.value}
                onChange={(change) => e.setter(filterText(change.target.value))}
              />
            </div>
          ))}
        </div>
        Green Letters:
        <div
          ref={posInputRef}
          className="positionalInput"
        >
          { posArray.map((e, i: number) => (
            <input
              key={i}
              name={`green${i + 1}`}
              value={e.value}
              onChange={() => {}}
            />
          ))}
        </div>
        <div className="checkbox">
          <div>Hard Mode:</div>
          <input
            type='checkbox'
            checked={hardmode}
            onChange={(e) => {setHardMode(e.target.checked)}}
          />
        </div>
      </form>
      <div className="guess">
        Try Guessing{guess}
        <div> 
          <button onClick={getWordleGuess}>
            Refresh Guess
          </button> <div className={`smallLoading ${!showSpin && "hide"}`}/>
        </div>
      </div>
      <div className="results">
        {results && results.word_pages 
          ? (results.word_pages.map((wordPage: WordPage, i: number) => 
            <div key={`word${i}`}>
              <WordList
                wordPage = {wordPage}
                query = {results.letters_for_search}
                urlFunc = {wordleURLFunc}
              />
            </div>
          )) : (
            <div>
              No Results
            </div>
          )
        }
      </div>
    </div>
  );
}

export default WordleSolver;
