import React, {CSSProperties} from 'react';
import WordList from './components/WordList';
import Options from './components/Options'
import { filterText, restrictCharacter } from './functions/functions'
import Wordle from './components/Wordle'
import WordleSolver from './components/WordleSolver'
import './App.css';

export interface customCSS extends CSSProperties {
  '--background': string
  '--color': string
  '--highlight': string
}

function App() {
  const [letters, setLetters] = React.useState("");
  const [results, setResults] = React.useState<WordFinderResult | null>(null)
  const [showSpin, setShowSpin] = React.useState(false)
  const [optionsChange, setOptionsChange] = React.useState(false)
  const [firstQuery, setFirstQuery] = React.useState(true)
  const optionsRef = React.useRef(null)

  React.useEffect(() => {
    setShowSpin(true);
    if (optionsRef.current) {
      // @ts-ignore
      fetch((optionsRef.current).urlFromOptions(letters))
      .then(res => {
        if (res.ok === true && firstQuery) {
          setFirstQuery(false)
        }
        return res.json()
      })
      .then((json: any) => {
        setShowSpin(false)
        setResults(json)
      })
      .catch(() => {})
    }
    // React Hook React.useEffect has a missing dependency but I intentionally did not include firstQuery as one
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letters, optionsChange])

  const queries = new URLSearchParams(window.location.search.slice(1))
  const title = queries.get("title") ?? "Words with letters"
  const style: customCSS = {
    '--background': `#${queries.get("background") ?? 'FFFFFF'}`,
    '--color': `#${queries.get("color") ?? '000000'}`,
    '--highlight': `#${queries.get("highlight") ?? 'FFFFFF'}`
  }

  React.useEffect(() => {
    setLetters(queries.get("letters") ?? "")
    // React Hook React.useEffect has a missing dependency but I intentionally have an empty dependency array to run this code only once on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (queries.get("mode") === "wordle") {
    return (
      <div className="words-with-letters" style={style}>
        <h1>{title}</h1>
        <div className="desc">
          Tool to help you solve wordle!
        </div>
        <Wordle/>
      </div>
    )
  } else if (queries.get('mode') === 'wordle-solver') {
    return (
      <div className="words-with-letters" style={style}>
        <h1>{title}</h1>
        <div className="desc">
          Tool to help you solve wordle!
        </div>
        <WordleSolver/>
      </div>
    )
  }
  
  return (
    <div className="words-with-letters" style={style}>
      <h1>{title}</h1>
      <div className="desc">
        Enter up to 15 letters and 3 wildcards (?) to find all possible words you can make with them!
      </div>
      <div className="input">
        <input 
          type="text"
          value={letters} 
          onChange={(e) => setLetters(restrictCharacter(filterText(e.target.value, '?'), '?', 3, 15))}
        />
        <div className={`loading ${!showSpin && "hide"}`}/>
      </div>
      <div onChange={() => {setOptionsChange((current) => !current)}}>
        <Options ref={optionsRef}/>
      </div>
      <div className="results">
        {results && results.word_pages 
          ? (results.word_pages.map((wordPage: WordPage, i: number) => 
            <div key={i}>
              <WordList
                wordPage = {wordPage}
                query = {results.letters_for_search}
                // @ts-ignore
                urlFunc = {optionsRef.current.urlFromOptions}
              />
            </div>
          )) : (
            !firstQuery && (
              <div>
                No Results
              </div>
            )
          )
        }
      </div>
    </div>
  );
}

export default App;
