export function filterText(word: string, additional?: string) {
  additional = additional ?? ""
  let wordAsArray: string[] = word.toLowerCase().split("").filter((letter: string) => 
    "thequickbrownfoxjumpsoverthelazydog".concat(additional as string).split("").includes(letter)
  )
  return wordAsArray.join("")
}

export function filterNumbers(text: string) {
  const int: number = parseInt(text.split("").filter((char: string) => 
    "0123456789".split("").includes(char)
  ).join(""))
  return isNaN(int) ? '' : int
}

export function restrictCharacter(word: string, character: string, count: number, length?: number) {
  let wordAsArray = word.split("")
  wordAsArray = wordAsArray.reverse()
  while (wordAsArray.filter((a) => a === character).length > count) {
    wordAsArray.splice(wordAsArray.indexOf(character), 1)
  }
  wordAsArray = wordAsArray.reverse()
  if (length) {
    wordAsArray = wordAsArray.slice(0, 15)
  }
  return wordAsArray.join("")
}

function hexToRGB(hex: string) {
  // @ts-ignore
  let hexvalues: string[] = hex.substr(1).match(/.{2}/g)
  return [
    parseInt(hexvalues[0], 16),
    parseInt(hexvalues[1], 16),
    parseInt(hexvalues[2], 16),
  ]
}

function luminance(hex: string) {
  let rgb: number[] = hexToRGB(hex)
  rgb = rgb.map((e: number) => e / 255)
  rgb = rgb.map((e: number) =>
    e > 0.03928 ? Math.pow((e + 0.055) / 1.055, 2.4) : e / 12.92
  )
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
}

function contrastRatio(hex1: string, hex2: string) {
  const lum1 = luminance(hex1)
  const lum2 = luminance(hex2)
  return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05)
}

export function contrastABvsHex(hex: string, a: string, b:string) {
  const aContrast = contrastRatio(hex, a)
  const bContrast = contrastRatio(hex, b)
  return aContrast > bContrast ? a : b
}