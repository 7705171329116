import React from 'react';
import { filterText } from '../functions/functions'
import WordList from './WordList';

function Wordle() {
  const [goodLetters, setGoodLetters] = React.useState("");
  const [badLetters, setBadLetters] = React.useState("");
  const [pos1, setPos1] = React.useState("")
  const [pos2, setPos2] = React.useState("")
  const [pos3, setPos3] = React.useState("")
  const [pos4, setPos4] = React.useState("")
  const [pos5, setPos5] = React.useState("")
  const [results, setResults] = React.useState<WordFinderResult | null>(null)
  const [firstQuery, setFirstQuery] = React.useState(true)
  const posInputRef = React.useRef(null)
  const posArray = [
    {
      value: pos1,
      setter: setPos1
    },
    {
      value: pos2,
      setter: setPos2
    },
    {
      value: pos3,
      setter: setPos3
    },
    {
      value: pos4,
      setter: setPos4
    },
    {
      value: pos5,
      setter: setPos5
    }
  ]

  function wordleURLFunc(letters: string, options?: urlFuncOptions) {
    const queries = new URLSearchParams(window.location.search.slice(1))
    const querySort = queries.get("sort")
    const sort: 'az' | 'za' | 'points' = querySort === 'az' || querySort === 'za' || querySort === 'points' ? querySort : 'az'
    let page_token = 1
    if (options) {
      page_token = options.page_token ?? 1
    }
    const string = `${pos1 ? pos1 : '_'}${pos2 ? pos2 : '_'}${pos3 ? pos3 : '_'}${pos4 ? pos4 : '_'}${pos5 ? pos5 : '_'}`
    return `https://fly.wordfinderapi.com/api/search?contains=${string}&exclude_letters=${badLetters}&include_letters=${goodLetters}&length=5&word_sorting=${sort}&group_by_length=true&page_size=20&dictionary=wordle&page_token=${page_token}`
  }

  React.useEffect(() => {
    //setShowSpin(true);
    fetch(wordleURLFunc(""))
    .then(res => {
      if (res.ok === true && firstQuery) {
        setFirstQuery(false)
      }
      return res.json()
    })
    .then((json: any) => {
      //setShowSpin(false)
      setResults(json)
    })
    .catch(() => {})

    // React Hook React.useEffect has a missing dependency but I intentionally did not include firstQuery as one
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodLetters, badLetters, pos1, pos2, pos3, pos4, pos5])

  React.useEffect(() => {
    const posInput = posInputRef.current
    const keyListener = function(event: KeyboardEvent) {
      if ("thequickbrownfoxjumpsoverthelazydog ".split("").includes(event.key)) {
        if (event.key === " ") {
          posArray[parseInt((event.target as HTMLInputElement).name) - 1].setter("_");
        } else {
          posArray[parseInt((event.target as HTMLInputElement).name) - 1].setter(event.key);
        }
        if ((event.target as HTMLInputElement).nextSibling !== null) {
          ((event.target as HTMLInputElement).nextSibling as HTMLElement).focus()
        }
      }
    }
    const deleteListener = function(event: KeyboardEvent) {
      if (event.key === "Backspace") {
        if ((event.target as HTMLInputElement).value !== "") {
          posArray[parseInt((event.target as HTMLInputElement).name) - 1].setter("");
          (event.target as HTMLInputElement)!.focus()
        } else if ((event.target as HTMLInputElement).previousSibling !== null) {
          ((event.target as HTMLInputElement).previousSibling as HTMLElement).focus();
          posArray[parseInt(((event.target as HTMLInputElement).previousSibling as HTMLInputElement).name) - 1].setter("")
        }
      }
    }
    if (posInput) {
      (posInput as HTMLDivElement).addEventListener('keypress', keyListener);
      (posInput as HTMLDivElement).addEventListener('keydown', deleteListener);
    }
    return () => {
      posInput && (posInput as HTMLDivElement).removeEventListener('keypress', keyListener)
      posInput && (posInput as HTMLDivElement).removeEventListener('keydown', deleteListener)
    }
  })

  return (
    <div className="wordle">
      <form>
        Good Letters:
        <input
          value={goodLetters}
          onChange={(e) => setGoodLetters(filterText(e.target.value))}
        />
        Bad Letters:
        <input
          value={badLetters}
          onChange={(e) => setBadLetters(filterText(e.target.value))}
        />
        Known Letter Positions:
        <div
          ref={posInputRef}
          className="positionalInput"
        >
          { posArray.map((e, i: number) => (
            <input
              key={i}
              name={`${i + 1}`}
              value={e.value}
              onChange={() => {}}
            />
          ))}
        </div>
      </form>
      <div className="results">
        {results && results.word_pages 
          ? (results.word_pages.map((wordPage: WordPage, i: number) => 
            <div key={i}>
              <WordList
                wordPage = {wordPage}
                query = {results.letters_for_search}
                urlFunc = {wordleURLFunc}
              />
            </div>
          )) : (
            !firstQuery && (
              <div>
                No Results
              </div>
            )
          )
        }
      </div>
    </div>
  );
}

export default Wordle;
