import React from 'react';

type WordListProps = {
  word: WordListElement
}

function Word({word}: WordListProps) {
  return (
    <>
      <li className="word">
        {word.word.split("").map((letter: string, i: number) => (
          <React.Fragment key={i}>
            {word.wildcards.includes(i)
              ?
                <span className="highlight">{letter}</span>
              :
                letter
            }
          </React.Fragment>
        ))}
        <div className="points">{word.points}</div>
      </li>
    </>
  );
}

export default Word;
